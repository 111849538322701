import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { BASE_URI, MASTER_DEALERID } from '../shared/Constants';
import axios from 'axios';
import { DealerContext } from '../shared/contexts/DealerContext'

const useStyles = makeStyles(() => ({
  menuHodlerOuter: {
    width: "100%",
    boxShadow: '0px 0px 43px -3px #00000042',
    position: 'sticky',
    padding: "10px",
    paddingLeft: "0px",
    display: "flex",
    justifyContent: "center",
    top: "0px",
    zIndex: "55",
    background: 'white',
    '@media (max-width: 613px)': {
      padding: 'unset',
    },
    '@media (min-width: 614px)': {
      padding: 'unset',
    }
  },
  menu: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 900px)": {
      padding: "15px 5px",
    },
  },
  menuHodler: {
    display: "flex",
    "@media (max-width: 900px)": {
      display: "none",
    },
  },
  Link: {
    padding: "19px 15px",
    color: "#274b84",
    fontSize: "14px",
    "&:hover": {
      color: "#2c88d9",
      cursor: "pointer",
    },
    "@media (max-width: 1037px)": {
      padding: "19px 5px",
    },
  },
  AfterSalesDropLink: {
    padding: "19px 30px",
    color: "#274b84",
    fontSize: "14px",
    "&:hover": {
      color: "#2c88d9",
      cursor: "pointer",
    },
  },
  LinkM: {
    padding: "19px 15px",
    color: "#274b84",
    "&:hover": {
      color: "#2c88d9",
      cursor: "pointer",
    },
  },
  BurgerHolder: {
    position: "relative",
    display: "none",
    "@media (max-width: 900px)": {
      display: "block",
      position: "relative",
    },
  },
  Burger: {
    display: "none",
    "@media (max-width: 900px)": {
      display: "block",
    },
  },
  pattie: {
    minWidth: "20px",
    minHeight: "2px",
    margin: "4px",
    background: ({ websiteColors }) => `${websiteColors.accentColor}`,
  },
  img: {
    width: "190px",
    paddingLeft: "20px",
    "@media (max-width: 768px)": {
      width: "180px",
    },
    "@media (min-width: 769px) and (max-width: 1200px)": {
      width: "200px",
    },
  },
  dropDown: {
    position: "fixed",
    width: "258px",
    right: "0px",
    background: "white",
    padding: "10px",
    overflow: 'auto',
    height: '100vh',
    zIndex: '3'
  },
  showRoomDropDown: {
    position: "absolute",
    background: "white",
    whiteSpace: "nowrap",
  },
  AfterSalesDrop: {
    position: 'absolute',
    background: 'white'
  },
  AfterSalesDropM: {
    background: 'white'
  },
}));

const TopBar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenuAfterSales, setOpenMenuAfterSales] = useState(false);
  const { globalDealer, websiteColors } = useContext(DealerContext);
  const [Logo, setLogo] = useState('');
  const classes = useStyles({ websiteColors });

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${BASE_URI}/DealerWebsites/${globalDealer.id} `
    }).then((response) => {
      setLogo(response.data.image)
    });
  }, [globalDealer]);

  const handleMenu = () => {
    if (openMenu) {
      setOpenMenu(false);
    } else {
      setOpenMenu(true);
    }
  };

  const handleMenuAfterSales = () => {
    if (openMenuAfterSales) {
      setOpenMenuAfterSales(false);
    } else {
      setOpenMenuAfterSales(true);
    }
  };

  const aLinks = [
    {
      link: '/show-room',
      title: 'New Cars'
    },
    {
      link: '/special-deals',
      title: 'Special Deals'
    },
    {
      link: '/used-vehicles',
      title: 'Used Cars'
    },
  ];

  const aLinks2 = globalDealer?.id === MASTER_DEALERID ? [
    {
      link: '/sell-your-vehicle',
      title: 'Sell My Car'
    },
    {
      link: '/contact-us',
      title: 'Contact Us'
    },
    {
      link: '/about',
      title: 'About Us'
    },
    // {
    //   link: '/events',
    //   title: 'Events'
    // }
  ] : [
    {
      link: '/apply-for-finance',
      title: 'Apply Online'
    },
    {
      link: '/sell-your-vehicle',
      title: 'Sell My Car'
    },
    {
      link: '/contact-us',
      title: 'Contact Us'
    },
    {
      link: '/about',
      title: 'About Us'
    },
    // {
    //   link: '/events',
    //   title: 'Events'
    // }
  ];

  const aDropdownLinks = [
    {
      link: '/parts',
      title: 'Parts'
    },
    {
      link: '/service',
      title: 'Service'
    },
    {
      link: '/accessories',
      title: 'Accessories'
    }
  ]

  return (
    <>
      <Box className={classes.menuHodlerOuter}>
        <Box className={classes.menu}>
          <div className={classes.img}>
            <Link
              to="/"
              onMouseOver={() => {
                setOpenMenuAfterSales(false)
              }}
            >
              <img src={Logo} width="100%"></img>
            </Link>
          </div>

          <div className={classes.BurgerHolder}>
            <div
              className={classes.Burger}
              onClick={() => {
                handleMenu();
              }}
            >
              <div className={classes.pattie}></div>
              <div className={classes.pattie}></div>
              <div className={classes.pattie}></div>
            </div>
          </div>
          <Box className={classes.menuHodler}>
            {aLinks.map((l) => (
              <Link
                to={l.link}
                onMouseOver={() => {
                  setOpenMenuAfterSales(false)
                }}
              >
                <Typography className={classes.Link}>{l.title}</Typography>
              </Link>
            ))}
            <div
              onClick={() => {
                handleMenuAfterSales();
              }}
            >
              <Typography className={classes.Link}>
                After Sales
              </Typography>
              {openMenuAfterSales ?
                <div className={classes.AfterSalesDrop}>
                  {aDropdownLinks.map((l) => (
                    <Link
                      to={l.link}
                    >
                      <Typography className={classes.AfterSalesDropLink}>{l.title}</Typography>
                    </Link>
                  ))}
                </div>
                : ""}
            </div>
            {aLinks2.map((l) => (
              <Link
                to={l.link}
                onMouseOver={() => {
                  setOpenMenuAfterSales(false)
                }}
              >
                <Typography className={classes.Link}>{l.title}</Typography>
              </Link>
            ))}
          </Box>
        </Box>
      </Box>
      {openMenu && (
        <div className={classes.dropDown}>
          <Link to="/">
            <Typography
              className={classes.LinkM}
              onClick={() => {
                handleMenu();
              }}
            >
              Home
            </Typography>
          </Link>
          {aLinks.map((l) => (
            <Link
              to={l.link}
              onClick={() => {
                handleMenu();
              }}
            >
              <Typography className={classes.LinkM}>
                {l.title}
              </Typography>
            </Link>
          ))}
          <div
            onClick={() => {
              handleMenuAfterSales();
            }}
          >
            <Typography className={classes.LinkM}>
              After Sales
            </Typography>
            {openMenuAfterSales ?
              <div className={classes.AfterSalesDropM}>
                {aDropdownLinks.map((l) => (
                  <Link
                    to={l.link}
                  >
                    <Typography className={classes.AfterSalesDropLink}>
                      {l.title}
                    </Typography>
                  </Link>
                ))}
              </div>
              : ""}
          </div>
          {aLinks2.map((l) => (
            <Link
              to={l.link}
              onClick={() => {
                handleMenu();
              }}
            >
              <Typography className={classes.LinkM}>
                {l.title}
              </Typography>
            </Link>
          ))}
        </div>
      )}
    </>
  );
};

export default TopBar;
