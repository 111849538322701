import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { BASE_URI } from '../shared/Constants';
import axios from 'axios';
import { DealerContext } from '../shared/contexts/DealerContext'

const useStyles = makeStyles(() => ({
  menuHodlerOuter: {
    width: "100%",
    position: 'sticky',
    padding: "0px 0px",
    display: "flex",
    justifyContent: "center",
    top: "0px",
    zIndex: "55",
    background: '#ffffff',
  },
  menu: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 768px)": {
      padding: "10px",
      justifyContent: 'space-between',
    },
  },
  menuHodler: {
    display: "flex",
    background: '#df0534',
    "@media (max-width: 1000px)": {
      display: "none",
    },
  },
  menuHodlerOutter: {
    display: "flex",
    "@media (max-width: 1000px)": {
      display: "none",
    },
  },
  trap: {
    borderBottom: '59px solid #df0534',
    borderLeft: '25px solid transparent',
    borderRight: '0px solid transparent',
    height: '0',
    width: '25px',
    "@media (max-width: 1000px)": {
      display: "none",
    },
  },
  Link: {
    padding: "19px 10px",
    color: "#ffffff",
    fontSize: "14px",
    textTransform: 'uppercase',
    "&:hover": {
      color: "#2c88d9",
      cursor: "pointer",
    },
  },
  LinkM: {
    padding: "19px 15px",
    color: "#274b84",
    textTransform: 'uppercase',
    "&:hover": {
      color: "#2c88d9",
      cursor: "pointer",
    },
  },
  BurgerHolder: {
    position: "relative",
    display: "none",
    "@media (max-width: 1000px)": {
      display: "block",
      position: "relative",
    },
  },
  Burger: {
    display: "none",
    "@media (max-width: 1000px)": {
      display: "block",
    },
  },
  pattie: {
    minWidth: "20px",
    minHeight: "2px",
    margin: "4px",
    background: "#1e1e1e",
  },
  img: {
    width: "190px",
    paddingLeft: "10px",
    "@media (max-width: 768px)": {
      width: "180px",
    },
    "@media (min-width: 769px) and (max-width: 1200px)": {
      width: "200px",
    },
  },
  dropDown: {
    position: "fixed",
    width: "258px",
    right: "0px",
    background: "white",
    padding: "10px",
    overflow: 'auto',
    height: '100vh',
    zIndex: '2'
  },
  showRoomDropDown: {
    position: "absolute",
    background: "white",
    whiteSpace: "nowrap",
  },
  AfterSalesDrop: {
    position: 'absolute',
    background: '#df0534'
  },
  AfterSalesDropM: {
    background: '#ffffff'
  },
  AfterSalesDropLink: {
    padding: "19px 30px",
    color: "white",
    fontSize: "14px",
    textTransform: 'uppercase',
    "&:hover": {
      color: "white",
      cursor: "pointer",
    },
  },
  AfterSalesDropLinkM: {
    padding: "19px 30px",
    color: "black",
    fontSize: "14px",
    textTransform: 'uppercase',
    "&:hover": {
      color: "black",
      cursor: "pointer",
    },
  },
}));

const TopBarMahindraRise = () => {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenuAfterSales, setOpenMenuAfterSales] = useState(false);
  let { globalDealer } = useContext(DealerContext);
  const [Logo, setLogo] = useState('');

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${BASE_URI}/DealerWebsites/${globalDealer.id} `
    }).then((response) => {
      setLogo(response.data.image)
    });
  }, [globalDealer]);

  const handleMenu = () => {
    if (openMenu) {
      setOpenMenu(false);
    } else {
      setOpenMenu(true);
    }
  };

  const handleMenuAfterSales = () => {
    if (openMenuAfterSales) {
      setOpenMenuAfterSales(false);
    } else {
      setOpenMenuAfterSales(true);
    }
  };

  const HandleNewTab = () => {
    window.open("/dealer/ben-morgenrood-mahindra---randfontein")
  };

  return (
    <>
      <Box className={classes.menuHodlerOuter}>
        <Box className={classes.menu}>
          <div className={classes.img}>
            <Link
              to="/"
            >
              <img src={Logo} width="100%"></img>
            </Link>
          </div>
          <div className={classes.BurgerHolder}>
            <div
              className={classes.Burger}
              onClick={() => {
                handleMenu();
              }}
            >
              <div className={classes.pattie}></div>
              <div className={classes.pattie}></div>
              <div className={classes.pattie}></div>
            </div>
          </div>
          <div className={classes.menuHodlerOutter}>
            <div className={classes.trap}>
            </div>
            <Box className={classes.menuHodler}>
              <Link
                to="/"
              >
                <Typography className={classes.Link}>home</Typography>
              </Link>
              <Link
                to="/orchard-series"
              >
                <Typography className={classes.Link}>orchard series</Typography>
              </Link>
              <Link
                to="/field-tractors"
              >
                <Typography className={classes.Link}>field tractors</Typography>
              </Link>
              <Link
                to="/about"
              >
                <Typography className={classes.Link}>About</Typography>
              </Link>
              <div
                onClick={() => {
                  handleMenuAfterSales();
                }}
              >
                <Typography className={classes.Link}>
                  After Sales
                </Typography>
                {openMenuAfterSales ?
                  <div className={classes.AfterSalesDrop}>
                    <Link
                      to="/parts"
                    >
                      <Typography className={classes.AfterSalesDropLink}>Parts</Typography>
                    </Link>
                    <Link
                      to="/service"
                    >
                      <Typography className={classes.AfterSalesDropLink}>Service</Typography>
                    </Link>
                    <Link
                      to="/accessories"
                    >
                      <Typography className={classes.Link}>Accessories</Typography>
                    </Link>
                  </div>
                  : ""}
              </div>
              <Link
                onClick={() => {
                  HandleNewTab()
                }}
              >
                <Typography className={classes.Link}>Mahindra SA</Typography>
              </Link>
              <Link
                to="/special-deals"
              >
                <Typography className={classes.Link}>Promotions</Typography>
              </Link>
              <Link
                to="/contact-us"
              >
                <Typography className={classes.Link}>Contact Us</Typography>
              </Link>
              {/* <Link
                to="/events"
              >
                <Typography className={classes.Link}>Events</Typography>
              </Link> */}
            </Box>
          </div>
        </Box>
      </Box>

      {openMenu && (
        <div className={classes.dropDown}>
          <Link to="/">
            <Typography
              className={classes.LinkM}
              onClick={() => {
                handleMenu();
              }}
            >
              HOME
            </Typography>
          </Link>
          <Link
            to="/orchard-series"
            onClick={() => {
              handleMenu();
            }}
          >  <Typography className={classes.LinkM}>orchard series</Typography></Link>
          <Link
            to="/field-tractors"
            onClick={() => {
              handleMenu();
            }}
          >  <Typography className={classes.LinkM}>field tractors</Typography></Link>
          <Link
            to="/about"
            onClick={() => {
              handleMenu();
            }}
          >
            <Typography className={classes.LinkM}>
              ABOUT
            </Typography>
          </Link>
          <div
            onClick={() => {
              handleMenuAfterSales();
            }}
          >
            <Typography className={classes.LinkM}>
              After Sales
            </Typography>
            {openMenuAfterSales ?
              <div className={classes.AfterSalesDropM}>
                <Link
                  to="/parts"
                  onClick={() => {
                    handleMenu();
                  }}
                >
                  <Typography className={classes.AfterSalesDropLinkM}>Parts</Typography>
                </Link>
                <Link
                  to="/service"
                  onClick={() => {
                    handleMenu();
                  }}
                >
                  <Typography className={classes.AfterSalesDropLinkM}>Service</Typography>
                </Link>
                <Link
                  to="/accessories"
                  onClick={() => {
                    handleMenu();
                  }}
                >
                  <Typography className={classes.AfterSalesDropLinkM}>Accessories</Typography>
                </Link>
              </div>
              : ""}
          </div>
          <Link
            onClick={() => {
              window.location.href = "/dealer/ben-morgenrood-mahindra---randfontein"
            }}
          >
            <Typography className={classes.LinkM}>
              Mahindra SA
            </Typography>
          </Link>

          <Link
            to="/special-deals"
          >
            <Typography className={classes.LinkM}>
              Promotions
            </Typography>
          </Link>
          <Link
            to="/contact-us"
            onClick={() => {
              handleMenu();
            }}
          >
            <Typography className={classes.LinkM}>Contact Us</Typography>
          </Link>
          <Link
            to="/events"
            onClick={() => {
              handleMenu();
            }}
          >
            <Typography className={classes.LinkM}>
              EVENTS
            </Typography>
          </Link>
        </div>
      )}
    </>
  );
};

export default TopBarMahindraRise;
