import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { DealerContext } from "../shared/contexts/DealerContext";
import { BASE_URI, DEALERID, MOTORGROUPID } from "../shared/Constants";
import { VehiclesFilterV2 } from "@red-build/leadcentre";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: "0px 0px",
    display: "flex",
    flexDirection: 'column',
    "@media (max-width: 768px)": {},
  },
  innerHolderFilter: {
    padding: '0px 20px 20px 20px',
    maxWidth: "1770px",
    width: '100%',
    margin: " 0 auto",
    'label': {
      color: 'white!important'
    }
  },
  HolderFilter: {
    paddingTop: '40px',
  },
  innerHolder: {
    maxWidth: "1770px",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: " 0 auto",
    width: "100%",
    paddingTop: "50px !important",
  },
  card: {
    width: "calc(100% / 3 - 80px )",
    minWidth: "calc(100% / 3 - 80px )",
    cursor: 'pointer',
    background: 'black',
    boxShadow: ({ websiteColors }) => `0px 3px 15px 0px ${websiteColors.primaryColor}`,
    margin: "30px 40px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '5px',
    "@media (max-width: 700px)": {
      width: "100%",
    },
    "@media (min-width: 701px) and (max-width: 999px)": {
      width: "calc(100% / 2 - 20px )",
    },
    "@media (min-width: 1000px) and (max-width: 1400px)": {
      width: "calc(100% / 3 - 20px )",
    },
    "@media (min-width: 1400px) and (max-width: 1500px)": {
      width: "calc(100% / 3 - 80px )",
    },
  },
  cardTextHolder: {
    padding: "20px 40px",
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    color: 'white',
    borderRadius: '0px 0px 5px 5px',
    "@media (max-width: 768px)": {
      padding: "20px 20px",
    },
  },
  cardImgHolder: {
    height: '350px',
    backgroundSize: 'cover',
    display: 'flex',
    objectFit: 'cover',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '5px 5px 0px 0px',
    "@media (max-width: 768px)": {
      height: '250px',
    },
  },
  cardTextHolderGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0px'
  },
  btnGroupHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px'
  },
  cta: {
    border: "none",
    borderRadius: '50px',
    padding: "10px 40px",
    background: ({ websiteColors }) => `${websiteColors.accentColor}`,
    color: "white",
    cursor: "pointer",
    display: 'block',
    "&:hover": {
      background: "  #9b161c",
      boxShadow: "0 0 5px 0  #9b161c",
    },
  },
  ctaG: {
    border: "none",
    borderRadius: '50px',
    padding: "10px 40px",
    background: "grey",
    color: "white",
    cursor: "pointer",
    display: 'block',
    "&:hover": {
      background: "  #9b161c",
      boxShadow: "0 0 5px 0  #9b161c",
    },
  },
  cardTextTitleHolder: {
    marginBottom: '10px'
  },
  cardTextTitle: {
    fontSize: '19px',
    fontWeight: '500',
  },
  pagHolder: {
    display: 'flex',
    justifyContent: 'center'
  },
  pagBtn: {
    border: "none",
    borderRadius: '50px',
    padding: "10px 40px",
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    color: "white",
    cursor: "pointer",
    margin: '10px',
    padding: '10px 40px',
    "&:hover": {
      background: "  #9b161c",
      boxShadow: "0 0 5px 0  #9b161c",
    },
  },
  pagNumberHolder: {
    textAlign: 'right',
    width: '100%',
    padding: '5px 10px',
  },
  intro: {
    textAlign: 'center',
    marginTop: '0px',
    fontSize: '2.8rem',
  },
  red: {
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
  },
}));

const UsedVehicles = () => {
  const history = useHistory();
  const { vehiclesList, loading } = useContext(VehicleContext);
  const { websiteColors } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });
  const [vehiclesPag, setVehiclesPag] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [count, setCount] = useState(20);

  let orientation = "row"

  const handleRedirect = (v) => {
    history.push(`/pre-owned/${v.brand}/${v.stockId}`, v);
  };

  const defaultColors = {
    primaryColor: "black",
    secondaryColor: "#0d6fd4",
    labelColor: 'black',
    InputColor: websiteColors.accentColor,
    borderColor: websiteColors.accentColor,
    background: "whitesmoke",
    selectColor: websiteColors.accentColor,
    trackColor: websiteColors.accentColor,
    checkColor: websiteColors.accentColor,
  };

  var formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 2
  });

  useEffect(() => {
    let startIndex = 0
    let endIndex = 20
    let pag = []

    for (let index = 0; index <= vehiclesList?.length / 20; index++) {
      pag?.push(vehiclesList?.slice(startIndex, endIndex))
      startIndex = endIndex
      endIndex = endIndex + 20
    }

    setVehiclesPag(pag);
  }, [vehiclesList])

  const handleNextPage = () => {
    if (vehiclesPag[pageIndex].length >= 20) {
      setCount(count + 20)
      setPageIndex(pageIndex + 1)
    }
  }
  const handleBackPage = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1)
    }
  }

  const scrollToTopButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  };
  
  return (
    <>
      <div className={classes.root}>
        <div className={classes.HolderFilter}>
          <p className={classes.intro}>Search Our <span className={classes.red}>Vehicles</span></p>
          {!loading &&
            <div className={classes.innerHolderFilter}>
              <VehiclesFilterV2
                pageSize={20}
                dealerId={DEALERID}
                motorgroupId={MOTORGROUPID}
                orientation={orientation}
                updateFilteredVehicles={setVehiclesPag}
                loading={loading}
                vehicles={vehiclesList}
                base_uri={BASE_URI}
                websiteColors={defaultColors}
              />
            </div>
          }
        </div>

        <div className={classes.innerHolder}>
          {vehiclesPag[pageIndex]?.map((v, index) => {
            return (
              <div
                className={classes.card}
                onClick={() => {
                  handleRedirect(v);
                  scrollToTopButtonClick(v);
                }}
              >
                <img className={classes.cardImgHolder} src={v?.image} >
                </img>
                <div className={classes.cardTextHolder}>
                  <div className={classes.cardTextTitleHolder}>
                    <div className={classes.cardTextTitle}>{v?.model}</div>
                  </div>
                  <div className={classes.cardTextHolderGroup}>
                    <div>Mileage</div>
                    <div>{v?.mileage} km</div>
                  </div>
                  <div className={classes.cardTextHolderGroup}>
                    <div>Price</div>
                    <div>{formatter?.format(v?.price)}</div>
                  </div>
                  <div className={classes.cardTextHolderGroup}>
                    <div>Dealership</div>
                    <div>{v?.dealershipName}</div>
                  </div>
                  <div className={classes.cardTextHolderGroup}>
                    <div>Colour</div>
                    <div>{v?.colour}</div>
                  </div>
                  <div className={classes.cardTextHolderGroup}>
                    <div>Year</div>
                    <div>{v?.year}</div>
                  </div>
                  {v?.fuelType != null && (
                  <div className={classes.cardTextHolderGroup}>
                    <div>Fuel Type</div>
                    <div>{v?.fuelType}</div>
                  </div>
                    )}
                  <div className={classes.btnGroupHolder}>
                    <button className={classes.cta}>APPLY NOW</button>
                    <button className={classes.ctaG} onClick={(event) => { scrollToTopButtonClick(event) }}>LEARN MORE</button>
                  </div>
                </div>
              </div>
            );
          })}

          <div className={classes.pagNumberHolder}>{pageIndex} / {(vehiclesList?.length / 20).toFixed(0)}</div>
        </div>
        <div className={classes.pagHolder}>
          <button className={classes.pagBtn} onClick={() => { handleBackPage() }}>Back </button>
          <button className={classes.pagBtn} onClick={() => { handleNextPage() }}>Next</button>
        </div>
      </div>
    </>
  );
};

export default UsedVehicles;
