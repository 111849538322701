/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable radix */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable eqeqeq */
import React, { useEffect, useState, useContext, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import { ContactUs } from '@red-build/leadcentre';
import axios from 'axios';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { DealerContext } from '../shared/contexts/DealerContext';
import { VehicleContext } from '../shared/contexts/VehicleContext';
import { MASTER_DEALERID, BASE_URI } from '../shared/Constants';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  innerHolder: {
    maxWidth: '1360px',
    width: '100%',
    paddingTop: '40px',
    background: 'white',
    marginBottom: '4rem',
  },
  cardContent: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    borderBottom: '1px solid black',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardContentItem: {
    display: 'flex',
    padding: '10px 0px',
    borderBottom: '2px #8080802e solid',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  accent: {
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
  },
  titleTop: {
    fontSize: '20px',
    fontWeight: 'unset',
  },
  ImgHolder: {
    display: 'flex',
    width: '66%',
    paddingRight: '2rem',
    '@media (max-width: 760px)': {
      flexDirection: 'column',
    },
    '@media (max-width: 1170px)': {
      width: '100%',
      paddingRight: '0rem',
      marginBottom: '2rem',
    },
  },
  ImgGalleryHolder: {
    position: 'relative',
  },
  ImgGalleryHolderInner: {
    display: 'flex',
    transform: ({ galleryPos }) => `translate(${galleryPos}%,0%)`,
  },
  GalleryImg: {
    minWidth: 'calc(100% / 6 - 10px)',
    margin: '5px',
    width: '100%',
    height: '90px',
  },
  imgWide: {
    width: '100%',
    overflow: 'hidden',
    '@media (max-width: 760px)': {
      width: 'unset',
    },
  },
  Link: {
    border: 'none',
    borderRadius: '0.25rem',
    padding: '10px 40px',
    background: ({ websiteColors }) => `${websiteColors.accentColor}`,
    color: 'white',
    cursor: 'pointer',
    '&:hover': {
      background: '  #083246d1',
      boxShadow: '0 0 5px 0  #083246d1',
    },
  },
  titleFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    flexWrap: 'wrap',
    borderTop: '2px solid lightGray',
    borderBottom: '2px solid lightGray',
    padding: '1rem 0rem',
    margin: '1rem 2rem',
    '@media (max-width: 768px)': {
      borderTop: 'none',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  nextbtn: {
    background: '#d7d6d6',
    border: 'none',
    right: '0',
    padding: '10px',
    color: 'black',
    opacity: '0.5',
    position: 'absolute',
    top: '50%',
    transform: 'translate(0px, -50%)',
    '&:hover': {
      background: '#083246',
    },
  },
  backbtn: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0px, -50%)',
    background: '#d7d6d6',
    border: 'none',
    left: '0',
    padding: '10px',
    color: 'black',
    opacity: '0.5',
    '&:hover': {
      background: '#083246',
    },
  },
  vehicleInfo: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0rem 2rem',
    '@media (max-width: 1170px)': {
      flexDirection: 'column',
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    width: '33%',
    '@media (max-width: 1170px)': {
      width: '100%',
    },
  },
  dealerContact: {
    display: 'flex',
    flexDirection: 'row',
    margin: '2rem',
    '@media (max-width: 1170px)': {
      flexDirection: 'column',
    },
  },
  enquiry: {
    display: 'flex',
    flexDirection: 'column',
    width: '33%',
    '@media (max-width: 1170px)': {
      width: '100%',
    },
  },
  dealership: {
    display: 'flex',
    flexDirection: 'column',
    width: '66%',
    paddingRight: '2rem',
    '@media (max-width: 1170px)': {
      width: '100%',
      paddingRight: '0rem',
    },
  },
  mapHolder: {
    width: '50%',
    '@media (max-width: 1170px)': {
      width: '100%',
    },
  },
  text: {
    fontSize: '16px',
    maxWidth: '70%',
  },
  title2: {
    textAlign: 'center',
    color: '#466574',
    fontSize: '2rem',
    marginBottom: '1rem',
  },
  dealerInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    marginBottom: '2rem',
    '@media (max-width: 1170px)': {
      width: '100%',
      paddingRight: '0rem',
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    '@media (max-width: 1170px)': {
      flexDirection: 'column',
      marginBottom: '2rem',
    },
  },
  titleRight: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    '@media (max-width: 768px)': {
      textAlign: 'left',
      marginTop: '1rem',
    },
  },
}));

const DetailedUsedVehicle = () => {
  const [activeVehicle, setActiveVehicle] = useState();
  const history = useHistory();
  const [activeGallery, setActiveGallery] = useState([]);
  const [activeGalleryIndex, setActiveGalleryIndex] = useState(0);
  const [galleryPos, setGalleryPos] = useState(0);
  const enquiryRef = useRef(null);
  const params = useParams();
  const { vehiclesList } = useContext(VehicleContext);
  const { dealerList, websiteColors } = useContext(DealerContext);
  const [activeDealer, setActiveDealer] = useState();
  const [dealerLinks, setDealerLinks] = useState();
  const classes = useStyles({ galleryPos, websiteColors });
  const [randfontain, setRandfontain] = useState();

  useEffect(() => {
    setActiveVehicle(vehiclesList.filter((v) => v.stockId == params?.stockId)[0]);
  }, [vehiclesList]);

  useEffect(() => {
    setRandfontain(dealerList[1]);
  },[dealerList]);

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${BASE_URI}/stock/${params?.stockId}`,
    })
      .then((response) => {
        setActiveGallery(response.data.mainUrls);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn('Error fetching used vehicle data', error);
      });

    if (activeVehicle?.dealerId) {
      const getDealerWebsites = async (dealerID) => {
        axios({
          method: 'GET',
          url: `${BASE_URI}/DealerWebsites/${dealerID}`,
        })
          .then((res) => {
            const oLinks = res.data.links.reduce(
              (ac, v) => ({ ...ac, [v?.name.replace(' ', '')]: v?.link }),
              {}
            );
            setDealerLinks(oLinks);
          })
          .catch(() => null);
      };

      getDealerWebsites(activeVehicle?.dealerId);
    }
    if (activeVehicle?.dealerId !== MASTER_DEALERID) {
      setActiveDealer(dealerList.filter((d) => d?.dealerId == activeVehicle?.dealerId)[0]);
    }
    if (dealerList?.length > 0) {
      setActiveDealer(dealerList[0]);
    }
  }, [activeVehicle]);

  const formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 2,
  });

  const handleBack = () => {
    history.push('/used-vehicles');
  };

  const handleGalleryNext = () => {
    if (Math.abs(galleryPos) < Math.abs(((activeGallery.length - 1) / 2) * 16.66)) {
      setGalleryPos(galleryPos - 16);
    }
  };

  const handleGalleryBack = () => {
    if (galleryPos < 0) {
      setTimeout(() => {
        setGalleryPos(galleryPos + 16);
      }, 100);
    }
  };

  const handleGalleryIndex = (index) => {
    setActiveGalleryIndex(index);
  };

  const scrollToTopButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  };

  function calculateMonthly(data) {
    const deposit = data?.price * (10 / 100);
    const totalPrice = data?.price;
    const initFee = 0;
    const principal = totalPrice - deposit + initFee;
    const balloonPerc = 10;
    const balloonAmt = (totalPrice * balloonPerc) / 100;
    const interestRate = 9;
    const interestPM = interestRate / 100 / 12;
    const repaymentPeriod = 72;
    const days = 1;
    const v = 1 / (1 + interestPM);
    const d = 1 - v;
    const fp = principal * (1 + interestPM) ** (days / (365 / 12).toFixed());
    let monthly = 0;

    if (parseInt(balloonAmt) > 0) {
      const comp = (1 - v ** (repaymentPeriod - 1)) / d;
      monthly = (fp - balloonAmt * v ** (repaymentPeriod - 1)) / comp;
    } else {
      const comp = (1 - v ** repaymentPeriod) / d;
      monthly = fp / comp;
    }
    return Math.round(monthly * 100) / 100 + initFee;
  }


  return (
    <div className={classes.root}>
      <div className={classes.innerHolder}>
        <button
          type="button"
          style={{ marginLeft: '2rem' }}
          className={classes.Link}
          onClick={() => {
            handleBack();
            scrollToTopButtonClick();
          }}
        >
          Back To Results
        </button>
        <div className={classes.title}>
          <div className={classes.titleFlex}>
            <div style={{ display: 'flex', flexDirection: 'column', color: '#003145' }}>
              <Typography variant="h4" className={classes.titleTop} style={{ fontSize: '2rem' }}>
                {activeVehicle?.year} {activeVehicle?.brand} {activeVehicle?.model}
              </Typography>
              <Typography variant="body" className={classes.titleTop}>
                Stock No: {activeVehicle?.stockNo}
              </Typography>
            </div>

            <div className={classes.titleRight}>
              <Typography variant="h4" className={classes.titleTop} style={{ fontSize: '2rem' }}>
                {formatter.format(activeVehicle?.price)}
              </Typography>
              <Typography variant="h4" className={classes.titleTop}>
                Or {formatter.format(calculateMonthly(activeVehicle))} pm
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.vehicleInfo}>
          <div className={classes.ImgHolder}>
            <div className={classes.imgWide}>
              <img alt="" src={activeGallery[activeGalleryIndex]} width="100%" />
              <div className={classes.ImgGalleryHolder}>
                <div className={classes.ImgGalleryHolderInner}>
                  {activeGallery.map((i, index) => (
                    <div
                      className={classes.GalleryImg}
                      onClick={() => {
                        handleGalleryIndex(index);
                      }}
                    >
                      <img
                        alt=""
                        src={i}
                        width="100%"
                        height="100px"
                        style={{ objectFit: 'cover' }}
                      />
                    </div>
                  ))}
                </div>
                <button
                  type="button"
                  onClick={() => {
                    handleGalleryBack();
                  }}
                  className={classes.backbtn}
                >
                  <ArrowBackIosNewIcon />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    handleGalleryNext();
                  }}
                  className={classes.nextbtn}
                >
                  <ArrowForwardIosIcon />
                </button>
              </div>
            </div>
          </div>
          <div className={classes.info}>
            <div className={classes.cardContent}>
              <button
                type="button"
                onClick={() => enquiryRef.current.scrollIntoView({ behavior: 'smooth' })}
                className={classes.Link}
              >
                Enquire Now
              </button>
              {activeVehicle?.year != null && (
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Year
                </Typography>
                <Typography variant="body" className={classes.accent}>
                {activeVehicle?.year}
                </Typography>
              </div>
              )}
              {activeVehicle?.brand != null && (
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Make
                </Typography>
                <Typography variant="body" className={classes.accent}>
                {activeVehicle?.brand}
                </Typography>
              </div>
              )}
              {activeVehicle?.model != null && (
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Model
                </Typography>
                <Typography variant="body" className={classes.accent}>
                {activeVehicle?.model}
                </Typography>
              </div>
              )}
              {activeVehicle?.mileage != null && (
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Mileage
                </Typography>
                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.mileage} km
                </Typography>
              </div>
              )}
              {activeVehicle?.colour != null && (
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Colour
                </Typography>
                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.colour}
                </Typography>
              </div>
              )}
              {activeVehicle?.bodyType != null && (
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Body Type
                </Typography>
                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.bodyType}
                </Typography>
              </div>
              )}
              {activeVehicle?.transmission != null && (
                <div className={classes.cardContentItem}>
                  <Typography variant="h6" className={classes.titleTop}>
                    Transmission
                  </Typography>
                  <Typography variant="body" className={classes.accent}>
                    {activeVehicle?.transmission}
                  </Typography>
                </div>
              )}
              {activeVehicle?.fuelType != null && (
                <div className={classes.cardContentItem}>
                  <Typography variant="h6" className={classes.titleTop}>
                    Fuel Type
                  </Typography>
                  <Typography variant="body" className={classes.accent}>
                    {activeVehicle?.fuelType}
                  </Typography>
                </div>
              )}
            </div>
            {activeVehicle?.comments != null && (
              <div>
            <Typography variant="h3" className={classes.title2} style={{ margin: '1rem 0rem' }}>
              Seller's Notes
            </Typography>
            <p
              style={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                textAlign: 'justify',
                color: 'black',
                whiteSpace: 'pre-line',
              }}
            >
              {activeVehicle?.comments}
            </p>
            </div>
            )}
          </div>
        </div>
        <div className={classes.dealerContact}>
          <div className={classes.dealership}>
            <div className={classes.section}>
              <div className={classes.dealerInfo}>
                <Typography variant="h3" className={classes.title2}>
                  Dealership Information
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '1rem 0rem',
                    margin: '0rem 1rem',
                    borderBottom: '2px solid lightGray',
                  }}
                >
                  <Typography variant="body2" className={classes.text}>
                    Dealership
                  </Typography>
                  <Typography variant="body2" style={{ textAlign: 'right', fontSize: '16px' }}>
                    {randfontain?.name}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '1rem 0rem',
                    margin: '0rem 1rem',
                    borderBottom: '2px solid lightGray',
                  }}
                >
                  <Typography variant="body2" className={classes.text}>
                    Contact Us
                  </Typography>
                  <a
                    className={classes.text}
                    style={{ padding: '0px', color: 'black' }}
                    href={`tel:${randfontain?.newCarsPhoneNumber.replaceAll(' ', '')}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography variant="body2" style={{ textAlign: 'right', fontSize: '16px' }}>
                      {randfontain?.usedCarsPhoneNumber}
                    </Typography>
                  </a>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '1rem 0rem',
                    margin: '0rem 1rem',
                    borderBottom: '2px solid lightGray',
                  }}
                >
                  <Typography variant="body2" className={classes.text}>
                    Address
                  </Typography>
                  <a
                    className={classes.text}
                    style={{ padding: '0px', color: 'black' }}
                    href={
                      dealerLinks?.mapsURL
                        ? dealerLinks.mapsURL
                        : `https://www.google.com/maps/@${randfontain?.addressLongitude},${randfontain?.addressLatitude},16z?entry=ttu`
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography variant="h3" style={{ textAlign: 'right', fontSize: '16px' }}>
                      {randfontain?.address?.streetAddress},{' '}
                      {randfontain?.address?.streetAddressPostal}
                    </Typography>
                  </a>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '1rem 0rem',
                    margin: '0rem 1rem',
                    borderBottom: '2px solid lightGray',
                  }}
                >
                  <Typography variant="h3" className={classes.text}>
                    Business hours
                  </Typography>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingBottom: '10px',
                      }}
                    >
                      <Typography variant="h3" className={classes.text}>
                        Mon - Fri:
                      </Typography>
                      <Typography variant="h3" className={classes.text}>
                        08:00 - 17:00
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="h3" className={classes.text}>
                        Saturday:
                      </Typography>
                      <Typography variant="h3" className={classes.text}>
                        08:00 - 13:00
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.mapHolder}>
                <iframe
                  title="Map to Dealer"
                  src={
                    dealerLinks?.mapsEmbed
                      ? dealerLinks.mapsEmbed
                      : `https://maps.google.com/maps?q=${randfontain?.addressLongitude},${randfontain?.addressLatitude}&via=0&z=16&output=embed`
                  }
                  height="100%"
                  width="100%"
                  frameBorder="none"
                />
              </div>
            </div>
          </div>
          <div ref={enquiryRef} id="enquiry" className={classes.enquiry}>
            <Typography variant="h3" className={classes.title2}>
              Enquire Now
            </Typography>
            <div className={classes.cardContent}>
              <ContactUs
                dealerId={activeVehicle?.dealerId}
                base_uri={BASE_URI}
                leadTypeId={2}
                variantId={activeVehicle?.variantId}
                stockId={activeVehicle?.stockId}
                mmCode={activeVehicle?.mmCode}
                dealerName={activeVehicle?.dealershipName}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedUsedVehicle;
